//#region extendValidationSettings.  Set defaults that can be overridden

function extendValidationSettings(settings) {
  var extended = $.extend({}, {
    ignore: '',
    errorClass: "invalid",
    validClass: "valid",
    errorElement: "em",
    errorPlacement: function (error, element) {
      error.insertAfter(element.parent());
    },
    highlight: function (element, errorClass, validClass) {
      if (element.type === "radio") {
        this.findByName(element.name).addClass(errorClass).removeClass(validClass).parent().addClass('state-error').removeClass('state-success');
      } else {
        $(element).addClass(errorClass).removeClass(validClass).parent().addClass('state-error').removeClass('state-success');
      }
    },
    unhighlight: function (element, errorClass, validClass) {
      if (element.type === "radio") {
        this.findByName(element.name).removeClass(errorClass).addClass(validClass).parent().removeClass('state-error');
      } else {
        $(element).removeClass(errorClass).addClass(validClass).parent().removeClass('state-error');
      }
    },
    submitHandler: function (form) {
      isDirtyOverridden = true;

      if (fieldIdsToRemoveMask) {
        $.each(fieldIdsToRemoveMask, function (i, e) {
          var element = $('#' + e);

          element.inputmask('remove');

          if (element.hasClass('percentMask')) {
            element.val(getUnmaskedNumber(element.val()));
          }
        });
      }

      form.submit();
    }
  }, settings);

  return extended;
}

//#endregion

//#region Validators

//#region minCurrency

$.validator.addMethod('minCurrency', function (value, element, param) {
  var unmasked = value.replace(/[^\-0-9\.]+/g, "");
  var number = Number(unmasked);
  return this.optional(element) || number >= param;
}, "");

//#endregion

//#region daterange

$.validator.addMethod('daterange', function (value, element, args) {
  // Same as above

  var value = moment(value);

  if (value.isValid()) {
    var startDate = moment().startOf('day').subtract('days', args.daysBefore);
    var endDate = moment().startOf('day').add('days', args.daysAfter);

    if ((value.isAfter(startDate) || value.isSame(startDate)) && (value.isBefore(endDate) || value.isSame(endDate))) {
      return true;
    }
  }
  else {
    return true;
  }

  return false;
}, "");

//#endregion

//#region datespan

$.validator.addMethod('datespan', function (value, element, args) {
    // Same as above

    var startDate = moment($(args.startDate).val()).startOf('day');
    var endDate = moment($(args.endDate).val()).startOf('day');

    if (startDate.isValid() && endDate.isValid()) {
        var span = endDate.diff(startDate, 'days');
        if (span <= args.maxSpan) {
            return true;
        }
    }
    else {
        return true;
    }

    return false;
}, "");

//#endregion

//#region dateGreaterThan

//http://stackoverflow.com/questions/833997/end-date-greater-than-start-date-jquery-validation
$.validator.addMethod("dateGreaterThan",
function (value, element, params) {
  if (!value) {
    return true;
  }

  if (!/Invalid|NaN/.test(new Date(value))) {
    return new Date(value) > new Date($(params).val());
  }

  return isNaN(value) && isNaN($(params).val())
      || (Number(value) > Number($(params).val()));
}, 'Must be greater than {0}.');

//#endregion

//#region timeGreaterThan

$.validator.addMethod("timeGreaterThan",
function (value, element, params) {
  var startTime = moment("1/1/2000 " + value);

  var endTime = moment("1/1/2000 " + $(params).val());

  return startTime.isAfter(endTime);
}, 'Must be greater than {0}.');

//#endregion

//#region time

//http://stackoverflow.com/questions/7294131/time-validation-with-jquery
$.validator.addMethod("time", function (value, element) {
  if (!value) {
    return true;
  }

  return /^(([0-1]?[0-9])|([2][0-3])):([0-5]?[0-9])(:([0-5]?[0-9]))?(\s*)(am|pm)$/i.test(value);
}, "Please enter a valid time.");

//#endregion

//#region number_unmask

$.validator.addMethod("number_unmask", function (value, element) {
  // http://jqueryvalidation.org/number-method/
  return this.optional(element) || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(getUnmaskedNumber(value));
}, "Please enter a valid number.");

//#endregion

//#region currency

/**
 * Validates currencies with any given symbols by @jameslouiz
 * Symbols can be optional or required. Symbols required by default
 *
 * Usage examples:
 *  currency: ["£", false] - Use false for soft currency validation
 *  currency: ["$", false]
 *  currency: ["RM", false] - also works with text based symbols such as "RM" - Malaysia Ringgit etc
 *
 *  <input class="currencyInput" name="currencyInput">
 *
 * Soft symbol checking
 *  currencyInput: {
 *     currency: ["$", false]
 *  }
 *
 * Strict symbol checking (default)
 *  currencyInput: {
 *     currency: "$"
 *     //OR
 *     currency: ["$", true]
 *  }
 *
 * Multiple Symbols
 *  currencyInput: {
 *     currency: "$,£,¢"
 *  }
 */
$.validator.addMethod("currency", function (value, element, param) {
  if (param[0] == "disable") {
    return true;
  }

  var isParamString = typeof param === "string",
      symbol = isParamString ? param : param[0],
      soft = isParamString ? true : param[1],
      regex;

  symbol = symbol.replace(/,/g, "");
  symbol = soft ? symbol + "]" : symbol + "]?";
  //STH-12/17/2014: Modified to allow leading 0.
  //STH-8/13/2015: Modified to allow leading '- '.
  regex = "^[\\(|\\W]*[" + symbol + "[\\W]*([0-9]{1}[0-9]{0,2}(\\,[0-9]{3})*(\\.[0-9]{0,2})?|[0-9]{1}[0-9]{0,}(\\.[0-9]{0,2})?|0(\\.[0-9]{0,2})?|(\\.[0-9]{1,2})?)[\\)]*$";
  regex = new RegExp(regex);
  return this.optional(element) || regex.test(value);
}, "Please specify a valid currency");

//#endregion

//#region phoneUS

$.validator.addMethod("phoneUS", function (phone_number, element) {
  phone_number = phone_number.replace(/\s+/g, "");
  phone_number = phone_number.replace(/x____$/, "");
  phone_number = phone_number.replace(/_*/g, "");
  return this.optional(element) || phone_number.length > 9 &&
		phone_number.match(/^(\+?1-?)?(\([2-9]([02-9]\d|1[02-9])\)|[2-9]([02-9]\d|1[02-9]))-?[2-9]([02-9]\d|1[02-9])-?\d{4}(x\d{1,4})?$/);
}, "Please specify a valid phone number");

//#endregion

//#region zipcodeUS

$.validator.addMethod("zipcodeUS", function (value, element) {
  var zip = element.value;

  zip = zip.split("_").join("");

  //Checks the length of the zipcode now that placeholder characters are removed.
  if (zip.length === 6) {
    //Removes hyphen
    zip = zip.replace("-", "");
  }
  return this.optional(element) || /^\d{5}$|^\d{5}\-\d{4}$/.test(zip);
}, "The specified US ZIP Code is invalid");

//#endregion

//#region percent

$.validator.addMethod("percent", function (value, element) {
  return this.optional(element) || (value.replace('%', '') <= 100 && value.replace('%', '') >= 0);
}, "Please enter a value between 0% and 100%.");

//#endregion

//#region strongPassword

jQuery.validator.addMethod(
       "strongPassword",
       function (password, element) {
         if (!password) {
           return false;
         }

         if (password.length < 8) {
           return false;
         }

         //Must contain an upper-case letter
         if (password.match(/[A-Z]+/) == null) {
           return false;
         }

         //Must contain a lower-case letter
         if (password.match(/[a-z]+/) == null) {
           return false;
         }

         //Must contain a digit
         if (password.match(/\d+/) == null) {
           return false;
         }

         //Must contain a special-character
         if (password.match(/\W+/) == null) {
           return false;
         }

         return true;
       }, 'Password must contain at least one upper/lower case letter, one number, and one special character.'
   );

//#endregion strongPassword

//#region select2Email

jQuery.validator.addMethod("select2Email", function (value, element) {
  var values = value.split(',');

  for (var i = 0; i < values.length; i++) {
    var result = jQuery.validator.methods.email.call(this, values[i], element);

    if (!result) {
      return false;
    }
  }

  return true;
}, "One or more email addresses are invalid.");

//#endregion select2Email

$.validator.addMethod('allowSingleAmount', function (value, element, param) {
    var paramValue = getUnmaskedNumber($(param).val());
    var numberValue = getUnmaskedNumber(value);

    //Only allow 1 to be entered.
    if (paramValue > 0 && numberValue > 0) {
        return false;
    }

    return true;
}, "Either One Amount can be entered.");


//#endregion

$.validator.methods.maxlength = function (value, element, param) {
  //The textarea maxlength attribute and the default maxlenth validation method counts CRLF as one char whereas the value that is passed to the server is actually 2 chars.    
  //  Thus, users can experience an exception on save to due string truncation at the db level. This ammends the maxlength validation method to count correctly. This code    
  //  is utilizing the same logic as we use to populate the "characters remaining..." message.    
  var fullLength = value.length + (value.match(/\n/g) || '').length;
  return fullLength <= param;
};

$(document).ready(function () {
  //Adds a change event to date picker and select2 to fix a bug when changing the value, it does not trigger a validation check
  //http://stackoverflow.com/questions/22335120/jquery-validator-and-datepicker-click-not-validating/22338363#22338363

  $(document).on("change", ".datepicker, .select2-offscreen", function (ev) {
    if ($(this).valid()) {
      $(this).attr("aria-invalid", "false");
    }
  });
});